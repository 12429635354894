/* eslint-disable max-len */
import React from 'react';

export default function WaterIcon({ offset }) {
  return (
    <svg width="36" height="36" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="myGradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="white" />
          <stop offset={`${offset}%`} stopColor="white" />
          <stop offset={`${offset}%`} stopColor="#B5DDEE" />
          <stop offset="100%" stopColor="#B5DDEE" />
        </linearGradient>
      </defs>
      <path d="M26.2708 4.60398C25.9079 4.32049 25.4605 4.1665 25 4.1665C24.5394 4.1665 24.0921 4.32049 23.7292 4.60398C19.7708 7.62481 8.08332 17.479 8.14582 28.9581C8.14582 38.2498 15.7083 45.8331 25.0208 45.8331C34.3333 45.8331 41.8958 38.2706 41.8958 28.979C41.9167 17.6665 30.2083 7.64564 26.2708 4.60398Z" fill="url(#myGradient)" stroke="#46ABD5" strokeWidth="2.2" strokeMiterlimit="10" />
    </svg>

  );
}
