import React from 'react';
import { useTranslation } from 'react-i18next';

import inIcon from '@assets/inIcon.png';
import ColorButton from '@UI/ColorButton';
import StyledSwitch from '@UI/StyledSwitch';
import maxValueFromFB from '@utils/maxValueFromFB';

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  titleText: {
    fontWeight: '700',
    color: '#404040',
    fontSize: 18,
    fontFamily: 'NunitoSans',
    textAlign: 'center',
  },

  backIconStyle: {
    height: 22,
    width: 22,
    transform: 'rotate(180deg)',
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
};

function TempHeader(props) {
  const {
    setIsModalOpen,
    conditioner,
    values,
    changeValue,
  } = props;
  const { t } = useTranslation();
  const toggleValues = () => conditioner[0].capabilities.find((item) => item.type.type_name === 'toggle');

  const checkSwitchBool = () => {
    const largestNumber = maxValueFromFB(toggleValues());
    return largestNumber === 1;
  };
  const onPressToggle = (val) => {
    const resVal = val === 1 ? 0 : 1;
    changeValue(toggleValues().address, resVal);
  };
  return (
    <div style={styles.container}>
      <div style={{ ...styles.titleContainer, justifyContent: 'flex-start' }}>
        <div>
          <ColorButton
            onClick={() => setIsModalOpen(false)}
            style={{ flex: 1 }}
          >
            <img alt="inIcon" src={inIcon} style={styles.backIconStyle} />
          </ColorButton>
        </div>
      </div>
      <div style={{ ...styles.titleContainer, flex: 3 }}>
        <span style={styles.titleText}>
          {t('climateControl')}
        </span>
      </div>
      <div style={{ ...styles.titleContainer, justifyContent: 'flex-end' }}>
        {toggleValues() ? (
          <StyledSwitch
            checked={checkSwitchBool()}
            onChange={() => onPressToggle(values('toggle'))}
            inputProps={{ 'aria-label': 'ant design' }}
          />

        ) : (
          <div style={styles.titleContainer} />
        )}
      </div>
    </div>
  );
}

export default TempHeader;
