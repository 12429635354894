import React from 'react';
import Humidity from './Humidity';

const container = {
  display: 'flex',
  flex: 1,
  marginTop: 31,
  justifyContent: 'space-between',
};

function HumidityContainer(props) {
  const {
    humidifier, changeValue,
  } = props;

  return (
    humidifier?.length > 0 && (
    <div style={container}>
      <Humidity
        changeValue={(address, value) => changeValue(address, value)}
        humidifier={humidifier}
      />
    </div>
    )
  );
}

export default HumidityContainer;
