import React from 'react';
import { useTranslation } from 'react-i18next';
import multimediaIcon from '@assets/multimediaIcons/multimediaIcon.png';
import inIcon from '@assets/inIcon.png';

import ColorButton from '@UI/ColorButton';

const styles = {
  titleText: {
    fontFamily: 'NunitoSans',
    fontWeight: '700',
    color: '#404040',
    fontSize: 16,
    flex: 1,
  },
  multimediaButtonStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
};

function MultimediaContainer(props) {
  const {
    multimedia, setModalObject,
  } = props;
  const { t } = useTranslation();
  return (
    <div>
      {multimedia?.length > 0 && (
      <div className="lightContainer">
        <ColorButton
          style={styles.multimediaButtonStyle}
          onClick={() => setModalObject(true, 'multimedia')}
        >
          <img alt="multimediaIcon" src={multimediaIcon} style={{ height: 16, width: 22, paddingRight: 10 }} />
          <span style={styles.titleText}>
            {t('multimedia')}
          </span>
          <img alt="inIcon" src={inIcon} style={{ height: 22, width: 22 }} />
        </ColorButton>
      </div>
      )}
    </div>
  );
}

export default MultimediaContainer;
