import React from 'react';

import tvVolumeIconSub from '@assets/multimediaIcons/tvVolumeIconSub.png';
import tvVolumeIconAdd from '@assets/multimediaIcons/tvVolumeIconAdd.png';

import ColorButton from '@UI/ColorButton';

const styles = {
  mainContainer: {
    display: 'flex',
    flex: 1,
    backgroundColor: '#ECF7FB',
    borderRadius: 40,
    justifyContent: 'space-between',
    padding: 5,
    marginRight: 15,
  },
  volButtonStyle: {
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: 60,
  },
};

function Volume(props) {
  const {
    onPressToAction,
  } = props;
  return (
    <div style={styles.mainContainer}>
      <ColorButton
        onClick={() => onPressToAction('TV_volum_down')}
        style={styles.volButtonStyle}
      >
        <img alt="tvVolumeIconSub" src={tvVolumeIconSub} style={{ height: 22, width: 22 }} />
      </ColorButton>
      <ColorButton
        onClick={() => onPressToAction('TV_volum_up')}
        style={styles.volButtonStyle}
      >
        <img alt="tvVolumeIconAdd" src={tvVolumeIconAdd} style={{ height: 22, width: 22 }} />
      </ColorButton>
    </div>

  );
}

export default Volume;
