/* eslint-disable max-len */
import React from 'react';

function AutoSwim({ fill }) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.1995 19.6737C10.435 19.6943 10.6426 19.5201 10.6632 19.2846C10.6838 19.0492 10.5096 18.8416 10.2741 18.821L9.34912 18.7401L19.912 9.87673C20.0931 9.7248 20.1167 9.45485 19.9648 9.27379C19.8129 9.09272 19.5429 9.06911 19.3618 9.22104L8.79893 18.0844L8.87986 17.1594C8.90046 16.9239 8.72628 16.7163 8.49081 16.6957C8.25535 16.6751 8.04777 16.8493 8.02717 17.0848L7.85619 19.0391C7.83559 19.2745 8.00977 19.4821 8.24523 19.5027L10.1995 19.6737Z" fill="#D3D8DF" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.7973 25.1245C22.991 24.989 23.038 24.7221 22.9024 24.5285C22.7669 24.3349 22.5 24.2878 22.3064 24.4234L21.5458 24.956L23.9402 11.3766C23.9812 11.1438 23.8258 10.9218 23.593 10.8808C23.3603 10.8397 23.1383 10.9952 23.0973 11.2279L20.7028 24.8074L20.1702 24.0467C20.0347 23.8531 19.7678 23.8061 19.5742 23.9416C19.3806 24.0772 19.3335 24.3441 19.4691 24.5377L20.5943 26.1447C20.7299 26.3383 20.9967 26.3853 21.1904 26.2498L22.7973 25.1245Z" fill="#D3D8DF" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.40387 6.60924C4.57101 6.77637 4.84198 6.77637 5.00912 6.60924C5.17625 6.44211 5.17625 6.17113 5.00912 6.00399L4.35254 5.34742L18.1415 5.34742C18.3778 5.34742 18.5694 5.15581 18.5694 4.91945C18.5694 4.68309 18.3778 4.49148 18.1415 4.49148L4.35254 4.49148L5.00912 3.8349C5.17625 3.66777 5.17625 3.39679 5.00912 3.22966C4.84198 3.06252 4.57101 3.06252 4.40387 3.22966L3.0167 4.61683C2.84957 4.78396 2.84957 5.05494 3.0167 5.22207L4.40387 6.60924Z" fill={fill} />
    </svg>

  );
}

export default AutoSwim;
