import React from 'react';

import AutoSwim from '@assets/modeIcons/AutoSwim';
import OneSwim from '@assets/modeIcons/OneSwim';
import TwoSwim from '@assets/modeIcons/TwoSwim';
import ThreeSwim from '@assets/modeIcons/ThreeSwim';

const textStyle = {
  fontFamily: 'NunitoSans',
  fontWeight: '600',
  fontSize: 14,
  paddingLeft: 8,
};

function ModeIconsSelectorSwim(type, color) {
  switch (type) {
    case 'auto':
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AutoSwim fill={color} />
          <span style={{ ...textStyle, color }}>Swim</span>
        </div>
      );
    case 'lamellas_1':
      return (
        <OneSwim fill={color} />
      );
    case 'lamellas_2':
      return (
        <TwoSwim fill={color} />
      );
    case 'lamellas_3':
      return (
        <ThreeSwim fill={color} />
      );
    default:
      return (null);
  }
} export default ModeIconsSelectorSwim;
