import React, { useState, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import unionIcon from '@assets/tempratureIcons/unionIcon.png';
import plusIcon from '@assets/tempratureIcons/plusIcon.png';

import ColorButton from '@UI/ColorButton';

import maxValueFromFB from '@utils/maxValueFromFB';

const styles = {
  buttonStyle: {
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: 30,
    padding: 10,
    alignItems: 'center',
    height: 30,
    width: 30,
  },

  titleStyle: {
    fontWeight: '700',
    color: '#404040',
    fontSize: 16,
    fontFamily: 'NunitoSans',
  },

  temRegulatorContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.5,
    padding: 6,
    borderRadius: 40,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#ECF7FB',
  },

  tempConainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 0.5,
  },

  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
  },

  tempText: {
    fontWeight: '400',
    color: '#6BBCDE',
    fontSize: 36,
    fontFamily: 'NunitoSans',
  },

  nowText: {
    fontWeight: '400',
    color: '#8C8C8C',
    fontSize: 14,
    fontFamily: 'NunitoSans',
  },

  titleSwitchContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 16,
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconStyle: {
    height: 10,
    width: 10,
  },
};
function Humidity({ humidifier, changeValue }) {
  const { t } = useTranslation();
  const pickedTypeOfConditionDevice = (pickedType) => humidifier[0].capabilities.find((item) => item.type.type_name === pickedType);
  const values = (pickedType) => {
    const largestNumber = maxValueFromFB(pickedTypeOfConditionDevice(pickedType));
    // console.log('pickedVal.fb2', pickedVal);
    return largestNumber || 0;
  };
  const [currSetpoint, setCurrSetpoint] = useState(values('setpoint'));
  console.log(humidifier);
  const debouncedChangeSetpoint = useCallback(debounce((address, val) => changeValue(address, val), 500), []);
  const debouncedUpdateState = (address, val) => {
    setCurrSetpoint(val);
    debouncedChangeSetpoint(address, val);
  };
  const onPress = (pickedType, val) => {
    const pickedItem = humidifier[0].capabilities.find((item) => item.type.type_name === pickedType);
    if (pickedType === 'setpoint') {
      if (val <= pickedItem.max_value && val >= pickedItem.min_value) {
        debouncedUpdateState(pickedItem.address, val);
      }
    }
  };

  useEffect(() => {
    setCurrSetpoint(values('setpoint'));
  }, [values('setpoint')]);
  return (
    <div className="tempContainer">
      <div style={styles.titleSwitchContainer}>
        <div style={styles.titleContainer}>
          <span style={styles.titleStyle}>
            {t('humidity')}
          </span>
        </div>
      </div>
      <div style={styles.container}>
        <div style={styles.temRegulatorContainer}>
          <ColorButton
            style={styles.buttonStyle}
            onClick={() => onPress('setpoint', currSetpoint - 1)}
            // disabled={!fb}
          >
            <img alt="unionIcon" src={unionIcon} style={styles.iconStyle} />
          </ColorButton>
          <ColorButton
            style={styles.buttonStyle}
            onClick={() => onPress('setpoint', currSetpoint + 1)}
            // disabled={!fb}
          >
            <img alt="plusIcon" src={plusIcon} style={styles.iconStyle} />
          </ColorButton>
        </div>
        <div style={styles.tempConainer}>
          <span style={styles.tempText}>
            {`${Math.trunc(currSetpoint)} %`}
          </span>
        </div>
      </div>
      <div style={{ paddingTop: 16 }}>
        <span style={styles.nowText}>
          {`${t('nowTemp')} ${values('humidity').toFixed(1)} %`}
        </span>
      </div>
    </div>

  );
}

export default Humidity;
