/* eslint-disable max-len */
import React from 'react';

function CoolIcon({ stroke = '#929EAF' }) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.0004 1.75V26.25M17.157 4.375C16.2043 4.94919 15.1127 5.25178 14.0004 5.25C12.8881 5.25178 11.7965 4.94919 10.8439 4.375M10.8439 23.625C11.7972 23.0524 12.8884 22.75 14.0004 22.75C15.1125 22.75 16.2036 23.0524 17.157 23.625M24.6093 7.875L3.3916 20.125M23.9136 11.9219C22.9409 11.3824 22.1333 10.5885 21.5773 9.6251C21.0214 8.66173 20.738 7.56524 20.7576 6.45312M4.08723 16.0781C5.05994 16.6176 5.86755 17.4115 6.42352 18.3749C6.97948 19.3383 7.26284 20.4348 7.24324 21.5469M3.3916 7.875L24.6093 20.125M4.08723 11.9219C5.05994 11.3824 5.86755 10.5885 6.42352 9.6251C6.97948 8.66173 7.26284 7.56524 7.24324 6.45312M23.9136 16.0781C22.9409 16.6176 22.1333 17.4115 21.5773 18.3749C21.0214 19.3383 20.738 20.4348 20.7576 21.5469" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default CoolIcon;
