import React from 'react';

const styles = {
  titleText: {
    fontFamily: 'NunitoSans',
    fontWeight: '700',
    color: '#404040',
    fontSize: 16,
    paddingBOttom: 35,
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
};

function DeviceWrapper(props) {
  const {
    deviceLength, children, image, title,
  } = props;
  return (
    deviceLength > 0 && (
      <div className="lightContainer">
        <div style={styles.titleContainer}>
          {image}
          <span style={styles.titleText}>
            {title}
          </span>
        </div>
        {children}
      </div>
    )
  );
}

export default DeviceWrapper;
