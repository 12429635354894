import React from 'react';
import MultimediaModalContainer from '@DeviceContainers/Multimedia/MultimediaModalContainer';
import TemperatureModalContainer from '@DeviceContainers/Temprature/TempModalComponents/TemperatureModalContainer';

function ModalChildrenSelector(type, setIsModalOpen, multimedia, conditioner, func) {
  switch (type) {
    case 'multimedia':
      return (
        <MultimediaModalContainer setIsModalOpen={(val) => setIsModalOpen(val)} multimedia={multimedia} func={func} />
      );
    case 'temperature':
      return (
        <TemperatureModalContainer setIsModalOpen={(val) => setIsModalOpen(val)} conditioner={conditioner} changeValue={func} />
      );
    default:
      return (null);
  }
} export default ModalChildrenSelector;
