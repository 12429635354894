import React from 'react';
import Drawer from '@mui/material/Drawer';
import ModalChildrenSelector from './ModalChildrenSelector';

const mainContainer = {
  display: 'flex',
  padding: 20,
  paddingTop: 15,
  flex: 1,
  flexDirection: 'column',
};

function Modal(props) {
  const {
    isModalOpen,
    setIsModalOpen,
    modalType,
    changeValue,
    multimedia,
    conditioner,
  } = props;
  return (
    <Drawer
      anchor="bottom"
      disableEnforceFocus
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
        },
      }}
    >
      <div style={mainContainer}>
        {ModalChildrenSelector(modalType, setIsModalOpen, multimedia, conditioner, changeValue)}
      </div>
    </Drawer>
  );
}

export default Modal;
