const sensorsRanges = (type) => {
  switch (type) {
    case 'noise_level':
      return ({
        good: '25',
        bad: '50',
      });
    case 'co2_concentration':
      return ({
        good: '300',
        bad: '600',
      });
    case 'voc':
      return ({
        good: '800',
        bad: '1400',
      });
    case 'CH2O':
      return ({
        good: '0',
        bad: '0.3',
      });
    case 'TVOC':
      return ({
        good: '65',
        bad: '660',
      });
    case 'light_level':
      return ({
        good: '300',
        bad: '200',
      });
    default:
      return (null);
  }
}; export default sensorsRanges;
