import React from 'react';

function TitleComponent({ type, title }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{
        fontFamily: 'NunitoSans',
        fontWeight: '400',
        color: '#404040',
        fontSize: 12,
      }}
      >
        {type}
      </span>
      <span style={{
        fontFamily: 'NunitoSans',
        fontWeight: '800',
        color: '#46ABD5',
        fontSize: 20,
      }}
      >
        {title}
      </span>
    </div>

  );
}

export default TitleComponent;
