import * as React from 'react';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const BootstrapInput = styled(InputBase)(() => ({
  '& .MuiInputBase-input': {},
  // '&.MuiInputBase-root:focus': {
  //   borderRadius: '30px',
  // },
  // '& .MuiList-root': { backgroundColor: 'red', borderRadius: '30px' },
  // MuiList-root MuiList-padding MuiMenu-list css-6hp17o-MuiList-root-MuiMenu-list
//   '& .MuiSvgIcon-root': { color: 'red' },
}));
const selectStyle = {
  width: 129,
  height: 40,
  borderRadius: '30px',
  borderWidth: 0,
  fontSize: '16px',
  color: '#46ABD5',
  padding: '10px 20px',
  backgroundColor: '#ECF7FB',
  borderColor: 'red',
  '& .MuiSvgIcon-root': { color: '#46ABD5', marginRight: '16px' },
  border: 0,
};
export default function Selector() {
  return (
    <div>
      {/* <FormControl variant="standard"> */}
      <Select
        sx={selectStyle}
        MenuProps={{
          PaperProps: {
            sx: {
              color: '#46ABD5',
              '& .MuiMenuItem-root': {
                boxShadow: '0px 0px',
              },
            },
          },
        }}
        labelId="customized-select-label"
        id="customized-select"
        value="30"
          // onChange={handleChange}
        input={<BootstrapInput />}
        IconComponent={ExpandMoreIcon}
      >
        <MenuItem value={10}>TV 1</MenuItem>
        <MenuItem value={20}>TV 2</MenuItem>
        <MenuItem value={30}>TV 3</MenuItem>
      </Select>
      {/* </FormControl> */}
    </div>
  );
}
