import React from 'react';
import { useTranslation } from 'react-i18next';
import curtainsIcon from '@assets/blindsIcons/curtainsIcon.png';
import DeviceWrapper from '@components/common/DeviceWrapper';
import Curtain from './Curtain';

const styles = {
  curtainsText: {
    fontFamily: 'NunitoSans',
    fontWeight: '700',
    color: '#46ABD5',
    fontSize: 14,
  },
};

function BlindsContainer(props) {
  const {
    blinds, changeValue,
  } = props;
  const { t } = useTranslation();
  return (
    <DeviceWrapper
      deviceLength={blinds.length}
      image={<img alt="curtainsIcon" src={curtainsIcon} style={{ height: 22, width: 25, paddingRight: 10 }} />}
      title={t('curtains')}
    >
      {(blinds.map((item) => (
        <div key={item.id} style={{ paddingTop: 35 }}>
          <div style={{ paddingBottom: 15 }}>
            <span style={styles.curtainsText}>
              {item.name}
            </span>
          </div>
          {(item?.capabilities?.map((val) => (
            <div key={val.id}>
              <Curtain item={val} func={(address, value) => changeValue(address, value)} />
            </div>
          )))}
        </div>
      )))}
    </DeviceWrapper>
  );
}

export default BlindsContainer;
