import * as React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import SCLogoLoading from '@assets/logos/SCLogoLoading.png';

export default function LoadingScreen() {
  return (
    <div
      className="container"
      style={{ alignItems: 'center', justifyContent: 'center' }}
    >
      <img alt="SCLogoLoading" src={SCLogoLoading} style={{ height: 67, width: 216, paddingBottom: 50 }} />
      <BeatLoader
        color="#3DBBEE"
        size={19}
        margin={3}
        speedMultiplier={0.5}
      />
    </div>
  );
}
