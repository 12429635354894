import AMPLITUDE_EVENT_NAMES from '../events';

const dayjs = require('dayjs');
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');

dayjs.extend(isSameOrAfter);

const clearSession = () => {
  localStorage.setItem('lastTimeActivity', new Date());
  localStorage.setItem('actionsPerSession', 0);
};

export const setSessionTime = () => {
  const lastTimeActivity = localStorage.getItem('lastTimeActivity');
  if (lastTimeActivity) {
    const newSessionStartTime = dayjs(lastTimeActivity).add(30, 'm').format();
    const isMoreThanSession = dayjs().isSameOrAfter(newSessionStartTime);
    if (isMoreThanSession) {
      clearSession();
    }
  } else {
    clearSession();
  }
  // console.log('lastTimeActivity Is', lastTimeActivity);
};

export const addActions = (event) => {
  const actionsPerSession = localStorage.getItem('actionsPerSession');
  let newAction = Number(actionsPerSession);
  if (AMPLITUDE_EVENT_NAMES.OPEN_APP !== event) {
    newAction = Number(actionsPerSession) + 1;
    localStorage.setItem('actionsPerSession', newAction);
  }
  localStorage.setItem('lastTimeActivity', new Date());
  const trackObj = {
    actionsPerSession: newAction,
    lastTimeActivity: new Date(),
  };
  return trackObj;
};
