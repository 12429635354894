import React from 'react';
import { useTranslation } from 'react-i18next';
import inIcon from '@assets/inIcon.png';

import ColorButton from '@UI/ColorButton';

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleText: {
    fontFamily: 'NunitoSans',
    fontWeight: '700',
    color: '#404040',
    fontSize: 18,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  backIconStyle: {
    height: 22,
    width: 22,
    transform: 'rotate(180deg)',
  },
};

function ModalHeader(props) {
  const { setIsModalOpen } = props;
  const { t } = useTranslation();
  return (
    <div style={styles.container}>
      <div style={{ ...styles.titleContainer, justifyContent: 'flex-start' }}>
        <div>
          <ColorButton
            onClick={() => setIsModalOpen(false)}
            style={{ flex: 1 }}
          >
            <img alt="inIcon" src={inIcon} style={styles.backIconStyle} />
          </ColorButton>
        </div>
      </div>
      <div style={{ ...styles.titleContainer, flex: 3 }}>
        <span style={styles.titleText}>
          {t('multimedia')}
        </span>
      </div>
      <div style={styles.titleContainer} />
    </div>
  );
}

export default ModalHeader;
