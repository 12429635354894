import React from 'react';
import { useTranslation } from 'react-i18next';
import lightIcon from '@assets/lightsIcons/lightIcon.png';
import DeviceWrapper from '@components/common/DeviceWrapper';
import ControlSelectorView from './ControlSelectorLightsView';

function LightsContainer(props) {
  const {
    lights, changeValue,
  } = props;
  const { t } = useTranslation();
  return (
    <DeviceWrapper
      deviceLength={lights.length}
      image={<img alt="curtainsIcon" src={lightIcon} style={{ height: 22, width: 22, paddingRight: 10 }} />}
      title={t('light')}
    >
      {(lights.map((item) => (
        <div
          key={item.id}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {item?.capabilities?.map((val) => (
              <div key={val.id}>
                {(typeof (val?.fb) === 'number')
              && (
              <ControlSelectorView
                val={val}
                changeValue={(address, value) => changeValue(address, value)}
                name={item.name}
              />
              ) }
              </div>
            ))}
          </div>
        </div>
      )))}
    </DeviceWrapper>
  );
}

export default LightsContainer;
