import React from 'react';

import AutoSpeedIcon from '@assets/modeIcons/AutoSpeedIcon';

function ModeIconsSelectorSpeed(type, color) {
  switch (type) {
    case 'auto':
      return (
        <AutoSpeedIcon fill={color} />
      );
    default:
      return (null);
  }
} export default ModeIconsSelectorSpeed;
