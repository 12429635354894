import { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/HomeStyle.css';
import apis from '@api/index';

const useGetRoomData = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const roomId = params.get('room_id');

  const [status, setStatus] = useState({
    loading: true,
    data: undefined,
    error: undefined,
  });

  const getFields = async (id) => {
    try {
      const result = await apis.getRoomData(id);
      setStatus({ loading: false, data: result.data });
    } catch (error) {
      setStatus({ loading: false, error });
    }
  };

  useEffect(() => {
    getFields(roomId);
  }, []);

  return useMemo(
    () => ({ ...status }),
    [status],
  );
};

export default useGetRoomData;
