/* eslint-disable max-len */
import React from 'react';

function ThreeSwim({ fill }) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.1995 19.6738C10.435 19.6944 10.6426 19.5202 10.6632 19.2848C10.6838 19.0493 10.5096 18.8417 10.2741 18.8211L9.34912 18.7402L19.912 9.87685C20.0931 9.72492 20.1167 9.45497 19.9648 9.27391C19.8129 9.09285 19.5429 9.06923 19.3618 9.22116L8.79893 18.0845L8.87986 17.1595C8.90046 16.924 8.72628 16.7165 8.49081 16.6959C8.25535 16.6753 8.04777 16.8494 8.02717 17.0849L7.85619 19.0392C7.83559 19.2747 8.00977 19.4822 8.24523 19.5028L10.1995 19.6738Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.7973 25.1247C22.991 24.9891 23.038 24.7222 22.9024 24.5286C22.7669 24.335 22.5 24.2879 22.3064 24.4235L21.5458 24.9561L23.9402 11.3767C23.9812 11.1439 23.8258 10.9219 23.593 10.8809C23.3603 10.8398 23.1383 10.9953 23.0973 11.228L20.7028 24.8075L20.1702 24.0469C20.0347 23.8532 19.7678 23.8062 19.5742 23.9418C19.3806 24.0773 19.3335 24.3442 19.4691 24.5378L20.5943 26.1448C20.7299 26.3384 20.9967 26.3855 21.1904 26.2499L22.7973 25.1247Z" fill="#BEC5CF" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.40387 6.60936C4.57101 6.7765 4.84198 6.7765 5.00912 6.60936C5.17625 6.44223 5.17625 6.17125 5.00912 6.00412L4.35254 5.34754L18.1415 5.34754C18.3778 5.34754 18.5694 5.15593 18.5694 4.91957C18.5694 4.68321 18.3778 4.4916 18.1415 4.4916L4.35254 4.4916L5.00912 3.83502C5.17625 3.66789 5.17625 3.39691 5.00912 3.22978C4.84198 3.06265 4.57101 3.06265 4.40387 3.22978L3.0167 4.61695C2.84957 4.78408 2.84957 5.05506 3.0167 5.22219L4.40387 6.60936Z" fill="#BEC5CF" />
    </svg>

  );
}

export default ThreeSwim;
