import React from 'react';

import CoolIcon from '@assets/modeIcons/CoolIcon';
import HeatIcon from '@assets/modeIcons/HeatIcon';
import AutoIcon from '@assets/modeIcons/AutoIcon';
import EcoIcon from '@/assets/modeIcons/EcoIcon';
import ShieldIcon from '@/assets/modeIcons/ShieldIcon';

function ModeIconsSelectorModes(type, color) {
  switch (type) {
    case 'cool':
      return (
        <CoolIcon stroke={color} />
      );
    case 'heat':
      return (
        <HeatIcon fill={color} />
      );
    case 'auto':
      return (
        <AutoIcon fill={color} />
      );
    case 'eco':
      return (
        <EcoIcon fill={color} />
      );
    case 'frost_protect':
      return (
        <ShieldIcon fill={color} />
      );
    default:
      return (null);
  }
} export default ModeIconsSelectorModes;
