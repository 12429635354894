import React from 'react';
import QualitySelector from '../helpers/QualitySelector';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 20,
  paddingBottom: 20,
  backgroundColor: '#FFF',
  boxShadow: '0px 0px 15px 0px rgba(146, 158, 175, 0.15)',
  borderRadius: 10,
  minWidth: '105px',
  marginTop: 10,
  marginBottom: 10,
};

const titleStyle = (color) => ({
  fontFamily: 'NunitoSans',
  fontWeight: '600',
  color,
  fontSize: 14,
  paddingTop: 10,
  paddingBottom: 4,
});

const descStyle = (color) => ({
  fontFamily: 'NunitoSans',
  fontWeight: '400',
  color,
  fontSize: 12,
  lineHeight: '21px',
});

function SensorContainer(props) {
  const {
    fb, typeOfSensor, title, prefix,
  } = props;
  const qualityTitleColor = (v) => QualitySelector(v, typeOfSensor)?.titleColor;
  const qualityDescColor = (v) => QualitySelector(v, typeOfSensor)?.descriptionColor;
  const qualityIcon = (v) => QualitySelector(v, typeOfSensor)?.img;
  const indicators = typeOfSensor === 'CH2O' ? Number(fb).toFixed(1) : Math.trunc(fb);
  return (
    <div style={containerStyle}>
      {qualityIcon(fb)}
      <span style={titleStyle(qualityTitleColor(fb))}>
        {title}
      </span>
      <span style={descStyle(qualityDescColor(fb))}>
        {`${indicators} ${prefix || ''}`}
      </span>
    </div>
  );
} export default SensorContainer;
