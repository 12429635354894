import React from 'react';
import '../styles/HomeStyle.css';

import logoLiis from '@assets/logos/logoLiis.png';
import LogoSС from '@assets/logos/logoSC.png';

function TopMenu({ logo }) {
  return (
    <div className="upMenu">
      <img
        alt="Logo"
        src={logo || logoLiis}
        style={{
          height: 'auto',
          width: 'auto',
          display: 'flex',
          maxHeight: 25,
        }}
      />
      <img alt="LogoSC" src={LogoSС} style={{ height: 32, width: 32 }} />
    </div>

  );
}

export default TopMenu;
