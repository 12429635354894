/* eslint-disable max-len */
import React from 'react';

function AutoIcon({ fill }) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3.75" y="3.75" width="20.5" height="20.5" rx="3.25" stroke={fill} strokeWidth="1.5" />
      <path d="M9.62695 17.9286L13.3866 9.61963H14.6241L18.3837 17.9286H16.8516L16.0266 16.0075H11.9723L11.1591 17.9286H9.62695ZM13.9877 11.2578L12.4909 14.8053H15.508L14.0112 11.2578H13.9877Z" fill={fill} />
    </svg>

  );
}

export default AutoIcon;
