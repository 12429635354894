import React from 'react';
import '../../styles/HomeStyle.css';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const ColorButtonStyle = styled(Button)(({ style }) => ({
  // color: 'black',
  ...style,
  // margin: "5px 0px",
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'flex-start',
  textAlign: 'start',
  // fontWeight: 'normal',
  textTransform: 'none',
  // fontWeight: '100',
  lineHeight: 1.2,
  letterSpacing: 0,
  fontFamily: 'default',
  minWidth: 0,
}));

function ColorButton({
  children, style, onClick, disabled = false,
}) {
  return (
    <ColorButtonStyle
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {children}
    </ColorButtonStyle>
  );
}

export default ColorButton;
