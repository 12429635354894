/* eslint-disable max-len */
import React from 'react';

function AutoSwim({ fill }) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.78085 11.0886C6.59591 10.9037 6.29608 10.9037 6.11115 11.0886L4.6885 12.5113C4.50357 12.6962 4.50357 12.9961 4.6885 13.181C4.87344 13.3659 5.17327 13.3659 5.3582 13.181L6.02489 12.5143C6.53868 17.8313 10.7685 22.0611 16.0854 22.5749L15.4188 23.2416C15.2338 23.4265 15.2338 23.7263 15.4188 23.9113C15.6037 24.0962 15.9035 24.0962 16.0885 23.9113L17.5111 22.4886C17.696 22.3037 17.696 22.0038 17.5111 21.8189L16.0885 20.3963C15.9035 20.2113 15.6037 20.2113 15.4188 20.3963C15.2338 20.5812 15.2338 20.881 15.4188 21.066L15.9619 21.6091C11.268 21.0555 7.54429 17.3318 6.99066 12.6379L7.53379 13.181C7.71872 13.3659 8.01856 13.3659 8.20349 13.181C8.38842 12.9961 8.38842 12.6962 8.20349 12.5113L6.78085 11.0886Z" fill={fill} />
      <path d="M4.7998 5.59998L20.1998 5.59998" stroke="#D3D8DF" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M23 25.2V9.79995" stroke="#D3D8DF" strokeWidth="1.5" strokeLinecap="round" />
    </svg>

  );
}

export default AutoSwim;
