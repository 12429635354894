import React, { useState, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import unionIcon from '@assets/tempratureIcons/unionIcon.png';
import plusIcon from '@assets/tempratureIcons/plusIcon.png';
import inIcon from '@assets/inIcon.png';

import ColorButton from '@UI/ColorButton';
import StyledSwitch from '@UI/StyledSwitch';

import maxValueFromFB from '@utils/maxValueFromFB';

const styles = {
  buttonStyle: {
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: 30,
    padding: 10,
    alignItems: 'center',
    height: 30,
    width: 30,
  },

  titleStyle: {
    fontWeight: '700',
    color: '#404040',
    fontSize: 16,
    fontFamily: 'NunitoSans',
  },

  temRegulatorContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.5,
    padding: 6,
    borderRadius: 40,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#ECF7FB',
  },

  tempConainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 0.5,
  },

  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
  },

  tempText: {
    fontWeight: '400',
    color: '#6BBCDE',
    fontSize: 36,
    fontFamily: 'NunitoSans',
  },

  nowText: {
    fontWeight: '400',
    color: '#8C8C8C',
    fontSize: 14,
    fontFamily: 'NunitoSans',
  },

  titleSwitchContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 16,
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconStyle: {
    height: 10,
    width: 10,
  },
};
function Temerature({ conditioner, changeValue, setIsModalOpen }) {
  const { t } = useTranslation();
  const pickedTypeOfConditionDevice = (pickedType) => conditioner[0].capabilities.find((item) => item.type.type_name === pickedType);
  const values = (pickedType) => {
    const largestNumber = maxValueFromFB(pickedTypeOfConditionDevice(pickedType));
    // console.log('pickedVal.fb2', pickedVal);
    return largestNumber || 0;
  };
  const [currSetpoint, setCurrSetpoint] = useState(values('setpoint'));

  const countTypeOfValue = (val) => {
    if (pickedTypeOfConditionDevice('setpoint').value === 'step') {
      // console.log('countTypeOfValue', val);
      return 2 * (val - 22);
    }
    return val;
  };

  const debouncedChangeSetpoint = useCallback(debounce((address, val) => changeValue(address, countTypeOfValue(val)), 500), []);
  const debouncedUpdateState = (address, val) => {
    setCurrSetpoint(val);
    debouncedChangeSetpoint(address, val);
  };
  const onPress = (pickedType, val) => {
    const pickedItem = conditioner[0].capabilities.find((item) => item.type.type_name === pickedType);
    if (pickedType === 'setpoint') {
      if (val <= pickedItem.max_value && val >= pickedItem.min_value) {
        debouncedUpdateState(pickedItem.address, val);
      }
    } if (pickedType === 'toggle') {
      const resVal = val === 1 ? 0 : 1;
      changeValue(pickedItem.address, resVal);
    }
  };

  const checkSwitchBool = (pickedType) => {
    const largestNumber = maxValueFromFB(pickedTypeOfConditionDevice(pickedType));
    return largestNumber === 1;
  };
  useEffect(() => {
    setCurrSetpoint(values('setpoint'));
  }, [values('setpoint')]);
  return (
    <div className="tempContainer">
      <div style={styles.titleSwitchContainer}>
        <div style={styles.titleContainer}>
          { pickedTypeOfConditionDevice('toggle') && (
            <div style={{ paddingRight: 12 }}>
              <StyledSwitch
                checked={checkSwitchBool('toggle')}
                onChange={() => onPress('toggle', values('toggle'))}
                inputProps={{ 'aria-label': 'ant design' }}
              />
            </div>
          )}
          <span style={styles.titleStyle}>
            {t('climateControl')}
          </span>
        </div>
        <ColorButton
          onClick={() => setIsModalOpen(true)}
        >
          <img alt="inIcon" src={inIcon} style={{ height: 22, width: 22 }} />
        </ColorButton>
      </div>
      <div style={styles.container}>
        <div style={styles.temRegulatorContainer}>
          <ColorButton
            style={styles.buttonStyle}
            onClick={() => onPress('setpoint', currSetpoint - 1)}
            // disabled={!fb}
          >
            <img alt="unionIcon" src={unionIcon} style={styles.iconStyle} />
          </ColorButton>
          <ColorButton
            style={styles.buttonStyle}
            onClick={() => onPress('setpoint', currSetpoint + 1)}
            // disabled={!fb}
          >
            <img alt="plusIcon" src={plusIcon} style={styles.iconStyle} />
          </ColorButton>
        </div>
        <div style={styles.tempConainer}>
          <span style={styles.tempText}>
            {`${Math.trunc(currSetpoint)} °C`}
          </span>
        </div>
      </div>
      <div style={{ paddingTop: 16 }}>
        <span style={styles.nowText}>
          {`${t('nowTemp')} ${values('temperature').toFixed(1)} °C`}
        </span>
      </div>
    </div>

  );
}

export default Temerature;
