import { useState, useEffect } from 'react';
import ControlSelector from './ControlSelectorLights';

function ControlSelectorView({ val, changeValue, name }) {
  const [fbC, setFbC] = useState(val?.fb);

  useEffect(() => {
    setFbC(val?.fb);
  }, [val]);
  return (
    ControlSelector(val, (val1, val2) => changeValue(val1, val2), fbC, (v) => setFbC(v), name)
  );
}
export default ControlSelectorView;
