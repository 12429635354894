import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGetRoomData from '@hooks/useGetRoomData';
import LoadingScreen from '@components/common/LoadingScreen';
import Home from '@screens/Home';

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontFamily: 'NunitoSans',
    fontWeight: '500',
    color: '#404040',
    fontSize: 16,
  },
};

function GetRoomScreen() {
  const { t } = useTranslation();
  const { data, loading, error } = useGetRoomData();

  const [lights, setLights] = useState([]);
  const [blinds, setBlinds] = useState([]);
  const [multimedia, setMultimedia] = useState([]);
  const [conditioner, setСonditioner] = useState([]);
  const [sensors, setSensors] = useState([]);
  const [connect, setConnect] = useState(false);
  // console.log('data?.devices?', data?.devices);
  // console.log('blinds', blinds);
  // console.log('lights', lights);
  const setDataPoints = () => {
    data?.devices?.forEach((item) => {
      // console.log('item', item);
      switch (item.type) {
        case 'light':
          setLights((prevVal) => [item, ...prevVal]);
          break;
        case 'blinds':
          setBlinds((prevVal) => [item, ...prevVal]);
          break;
        case 'TV':
          setMultimedia((prevVal) => [item, ...prevVal]);
          break;
        case 'climate':
          setСonditioner((prevVal) => [item, ...prevVal]);
          break;
        case 'sensors':
          setSensors((prevVal) => [item, ...prevVal]);
          break;
        default:
          break;
      }
    });
    setConnect(true);
  };

  useEffect(() => {
    if (data) {
      setDataPoints();
    }
  }, [data]);

  if (loading) return <LoadingScreen />;
  return (
    <div
      className="container"
      style={styles.container}
    >
      {(connect && !error)
        ? (
          <Home
            data={data}
            lights={lights}
            setLights={(val) => setLights(val)}
            blinds={blinds}
            setBlinds={(val) => setBlinds(val)}
            multimedia={multimedia}
            setMultimedia={(val) => setMultimedia(val)}
            conditioner={conditioner}
            setСonditioner={(val) => setСonditioner(val)}
            sensors={sensors}
            setSensors={(val) => setSensors(val)}
          />
        )

        : (
          <span style={styles.text}>
            {t('noRoom')}
          </span>
        )}
    </div>
  );
}

export default GetRoomScreen;
