import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import debounce from 'lodash/debounce';
import { RoundSlider } from 'mz-react-round-slider';
import ColorButton from '@UI/ColorButton';

import unionIcon from '@assets/tempratureIcons/unionIcon.png';
import plusIcon from '@assets/tempratureIcons/plusIcon.png';

const styles = {
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    // backgroundColor: 'red',
  },

  sliderContainer: {
    // backgroundColor: 'green',
    display: 'flex',
    position: 'relative',
    paddingRight: 20,
    paddingLeft: 20,
    justifyContent: 'center',
  },

  miniText: {
    fontFamily: 'NunitoSans',
    fontWeight: '400',
    color: '#929EAF',
    fontSize: 12,
    position: 'absolute',
    bottom: 60,
  },

  nowText: {
    fontFamily: 'NunitoSans',
    fontWeight: '400',
    color: '#929EAF',
    fontSize: 14,
    position: 'absolute',
    bottom: 30,
  },

  setpointText: {
    fontFamily: 'NunitoSans',
    fontWeight: '600',
    color: '#46ABD5',
    fontSize: 40,
  },
  setpointText2: {
    fontFamily: 'NunitoSans',
    fontWeight: '700',
    color: '#46ABD5',
    fontSize: 22,
  },

  temRegulatorContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    bottom: 12,
    position: 'absolute',
    width: 200,
    // backgroundColor: 'red',
  },

  buttonStyle: {
    display: 'flex',
    backgroundColor: '#ECF7FB',
    borderRadius: 40,
    padding: 10,
    alignItems: 'center',
    height: 40,
    width: 40,
  },
  firstRound: {
    display: 'flex',
    position: 'absolute',
    backgroundColor: '#ECF7FB',
    width: 204,
    height: 204,
    borderRadius: 2000,
    alignItems: 'center',
    justifyContent: 'center',
  },
  secondRound: {
    display: 'flex',
    position: 'absolute',
    backgroundColor: 'white',
    width: 168,
    height: 168,
    borderRadius: 2000,
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: ' 0px 0px 15px rgba(146, 158, 175, 0.15)',
  },
};

function TempSlider(props) {
  const {
    values,
    conditioner,
    changeValue,
  } = props;
  const { t } = useTranslation();
  const [currSetpoint, setCurrSetpoint] = useState([
    { value: values('setpoint') },
  ]);
  const setpointValues = () => conditioner[0].capabilities.find((item) => item.type.type_name === 'setpoint');

  const truncSetpoint = Math.trunc(currSetpoint[0].value);

  const countTypeOfValue = (val) => {
    if (setpointValues().value === 'step') {
      return 2 * (val - 22);
    }
    return val;
  };

  const debouncedChangeSetpoint = useCallback(debounce((val) => changeValue(setpointValues().address, countTypeOfValue(val)), 500), []);
  const debouncedUpdateState = (val) => {
    setCurrSetpoint([{ value: val }]);
    debouncedChangeSetpoint(val);
  };

  const onSlideSetpont = (val) => {
    const truncedVal = Math.trunc(val);
    setCurrSetpoint([{ value: val }]);
    // console.log('truncedVal', truncedVal, currSetpoint[0].value);
    if (truncedVal !== truncSetpoint) {
      debouncedChangeSetpoint(truncedVal);
    }
  };

  const onPressSetpont = (val) => {
    // console.log('pickedItem', val);
    if (val <= setpointValues().max_value && val >= setpointValues().min_value) {
      debouncedUpdateState(val);
    }
  };

  useEffect(() => {
    if (values('setpoint') !== truncSetpoint) {
      setCurrSetpoint([{ value: values('setpoint') }]);
    }
  }, [values('setpoint')]);

  return (
    <div style={styles.mainContainer}>
      <div style={styles.sliderContainer}>
        <span style={{ ...styles.miniText, left: 0 }}>
          {setpointValues()?.min_value ? `+${setpointValues()?.min_value}°C` : null}
        </span>
        <RoundSlider
          pathStartAngle={145}
          pathEndAngle={395}
          pathRadius={124}
          pathThickness={10}
          pointerBgColor="#ffffff"
          pointerBgColorSelected="#ffffff"
          pointerBorder={7}
          pointerBorderColor="#6bbcde"
          pointerRadius={10}
          pathBgColor="#F4F5F7"
          connectionBgColor="#6bbcde"
          pointers={currSetpoint}
          onChange={(arr) => onSlideSetpont(arr[0]?.value)}
          min={setpointValues()?.min_value}
          max={setpointValues()?.max_value}
          step={0.01}
          arrowStep={0.01}
          round={1}
          animateOnClick
          animationDuration={1200}
        />
        <span style={{ ...styles.miniText, right: 0 }}>
          {setpointValues()?.max_value ? `+${setpointValues()?.max_value}°C` : null}
        </span>
        <div style={styles.temRegulatorContainer}>
          <ColorButton
            style={styles.buttonStyle}
            onClick={() => onPressSetpont(truncSetpoint - 1)}
          >
            <img alt="unionIcon" src={unionIcon} style={{ height: 10, width: 10 }} />
          </ColorButton>
          <ColorButton
            style={styles.buttonStyle}
            onClick={() => onPressSetpont(truncSetpoint + 1)}
          >
            <img alt="plusIcon" src={plusIcon} style={{ height: 10, width: 10 }} />
          </ColorButton>
        </div>
      </div>
      <div style={styles.firstRound}>
        <div style={styles.secondRound}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span style={styles.setpointText}>
              {`+${truncSetpoint}.0`}
              <span style={styles.setpointText2}>
                °C
              </span>
            </span>
            <span style={styles.nowText}>
              {`${t('nowTemp')} ${values('temperature').toFixed(1)} °C`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TempSlider;
