import React from 'react';
import Box from '@mui/material/Box';
import ColorButton from '@UI/ColorButton';
import { logEvent } from '@libraries/AmplitudeAnalythics/init';
import AMPLITUDE_EVENT_NAMES from '@libraries/AmplitudeAnalythics/events';

const styles = {
  buttonStyle: {
    margin: '5px 0px',
    backgroundColor: '#FFFFFF',
    boxShadow: ' 0px 0px 20px rgba(146, 158, 175, 0.15)',
    padding: 20,
    borderRadius: 10,
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },

  logoStyle: {
    height: 'auto',
    width: 'auto',
    maxHeight: 25,
    maxWidth: 25,
  },

  titleText: {
    fontFamily: 'NunitoSans',
    fontWeight: '700',
    color: '#404040',
    fontSize: 14,
    paddingBottom: '4px',
  },

  descriptionText: {
    fontWeight: '400',
    color: '#404040',
    fontSize: 12,
    fontFamily: 'NunitoSans',
  },
  textContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flex: 1,
  },

};

function Scenario({ item, func }) {
  const onScenarioPress = () => {
    logEvent(AMPLITUDE_EVENT_NAMES.SCENARIO_CONTROL);
    const QOS = 1;
    item.actions.forEach((el, index) => {
      if (item.delay) {
        setTimeout(() => {
          func(el.capability.address, el.value, QOS);
        }, item.delay * index);
      } else {
        func(el.capability.address, el.value, QOS);
      }
    });
  };
  return (
    <ColorButton
      style={styles.buttonStyle}
      onClick={onScenarioPress}
    >
      <div style={styles.container}>
        <div style={{ paddingRight: 10 }}>
          {item.icon && (
          <img alt="Logo" src={item.icon} style={styles.logoStyle} />
          )}
        </div>
        <div style={styles.container}>
          <div style={styles.textContainer}>
            <Box
              component="span"
              sx={styles.titleText}
            >
              {item.title}
            </Box>
            <Box component="span" sx={styles.descriptionText}>
              {item.description}
            </Box>
          </div>
        </div>
      </div>
    </ColorButton>
  );
}

export default Scenario;
