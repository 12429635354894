import React from 'react';

import backTVIcon from '@assets/multimediaIcons/backTVIcon.png';
// import keyboardIcon from '@assets/keyboardIcon.png';
import settingsIcon from '@assets/multimediaIcons/settingsIcon.png';

import ColorButton from '@UI/ColorButton';

const styles = {
  mainContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    marginLeft: 15,
  },
  settingsButtonContainer: {
    display: 'flex',
    width: 40,
    height: 40,
    borderRadius: 40,
    backgroundColor: '#ECF7FB',
    alignItems: 'center',
  },
};

function OptionsButtons(props) {
  const {
    onPressToAction,
  } = props;
  return (

    <div style={styles.mainContainer}>
      {/* <ColorButton
        // onClick={() => setIsModalOpen(false)}
        style={settingsButtonContainer}
      >
        <img alt="keyboardIcon" src={keyboardIcon} style={{ height: 24, width: 24 }} />
      </ColorButton> */}
      <ColorButton
        onClick={() => onPressToAction('TV_home')}
        style={styles.settingsButtonContainer}
      >
        <img alt="settingsIcon" src={settingsIcon} style={{ height: 19, width: 19 }} />
      </ColorButton>
      <ColorButton
        onClick={() => onPressToAction('TV_back')}
        style={styles.settingsButtonContainer}
      >
        <img alt="backTVIcon" src={backTVIcon} style={{ height: 24, width: 24 }} />
      </ColorButton>
    </div>
  );
}

export default OptionsButtons;
