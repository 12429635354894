import * as amplitude from '@amplitude/analytics-browser';
import { isProd, isProdHost } from '@utils/helpers';
import { setSessionTime, addActions } from './utils/timeSessionSet';

export const amlitudeInitialization = () => {
  // console.log('isProdHost', isProdHost, window.location.hostname);
  if (isProd && isProdHost) {
    const apiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
    setSessionTime();
    amplitude.init(apiKey, { defaultTracking: { sessions: true } });
    // console.log('Amplitude init');
  }
};

export function logEvent(event, options) {
  if (isProd && isProdHost) {
    const trackOptions = addActions(event);
    const trackObj = {
      ...trackOptions,
      ...options,
    };
    amplitude.track(event, trackObj);
    // console.log('Amplitude logEvent: ', event, trackObj);
  }
}
