import axios from 'axios';
import { isProd, isProdHost } from '@utils/helpers';

// 'https://simple-control.liis.su/'; prod//
// 'https://dev.simple-control.liis.su/'; dev//
const api = isProd && isProdHost ? 'https://api.simple-control.liis.su' : 'https://api.dev.simple-control.liis.su';
const getAxiosRequest = () => axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export default {
  getRoomData(id) {
    const request = getAxiosRequest();
    return request.get(`${api}/rooms/${id}`);
  },
};
