import React from 'react';

import ColorButton from '@UI/ColorButton';

const styles = {
  mainContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 50,
  },

  TVbuttonStyle: {
    width: 7,
    height: 7,
    backgroundColor: 'rgba(70, 171, 213, 1)',
    borderRadius: 7,
    margin: 8,
  },

  OKbuttonStyle: {
    display: 'flex',
    backgroundColor: 'rgba(70, 171, 213, 1)',
    borderRadius: 84,
    width: 84,
    height: 84,
    alignItems: 'center',
    justifyContent: 'center',
  },

  OKbuttonContainer: {
    display: 'flex',
    background: '#F4F5F7',
    borderRadius: 100,
    width: 100,
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },

  OKbuttonText: {
    fontWeight: '600',
    color: 'white',
    fontSize: 17,
    textAlign: 'center',
  },

  centralContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

function JoyButton({ onPressToAction }) {
  return (
    <div>
      <ColorButton
        onClick={() => onPressToAction(false)}
        style={{ display: 'flex' }}
      >
        <div style={styles.TVbuttonStyle} />
      </ColorButton>
    </div>
  );
}

function Joystick(props) {
  const {
    onPressToAction,
  } = props;
  return (
    <div style={styles.mainContainer}>
      <div className="pultContainer">
        {/* up */}
        <JoyButton onPressToAction={() => onPressToAction('TV_arrow_up')} />
        <div style={styles.centralContainer}>
          {/* left */}
          <JoyButton onPressToAction={() => onPressToAction('TV_arrow_left')} />
          <div style={styles.OKbuttonContainer}>
            <ColorButton
              onClick={() => onPressToAction('TV_ok')}
              style={styles.OKbuttonStyle}
            >
              <span style={styles.OKbuttonText}>
                OK
              </span>
            </ColorButton>
          </div>
          {/* right */}
          <JoyButton onPressToAction={() => onPressToAction('TV_arrow_right')} />
        </div>
        {/* down */}
        <JoyButton onPressToAction={() => onPressToAction('TV_arrow_down')} />
      </div>
    </div>
  );
}

export default Joystick;
