import React from 'react';
import { useTranslation } from 'react-i18next';

import maxValueFromFB from '@utils/maxValueFromFB';
import TempSlider from './TempSlider';
import TempHeader from './TempHeader';

import ModeContainer from './ModeContainer';
import ModeIconsSelectorModes from './utils/ModeIconsSelectorModes';
import ModeIconsSelectorSpeed from './utils/ModeIconsSelectorSpeed';
import ModeIconsSelectorSwim from './utils/ModeIconsSelectorSwim';

const mainContainer = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
};

function TemperatureModalContainer(props) {
  const {
    setIsModalOpen, conditioner,
    changeValue,
  } = props;
  const { t } = useTranslation();
  const values = (pickedType) => {
    const pickedVal = conditioner[0].capabilities.find((item) => item.type.type_name === pickedType);
    const largestNumber = maxValueFromFB(pickedVal);
    // console.log('pickedVal.fb2', largestNumber);
    return largestNumber || 0;
  };
  const modes = conditioner[0].capabilities.find((item) => item.type.type_name === 'hvac_mode');
  const fan = conditioner[0].capabilities.find((item) => item.type.type_name === 'fan_speed');
  const lamellas = conditioner[0].capabilities.find((item) => item.type.type_name === 'lamellas');

  return (
    <div style={mainContainer}>
      <TempHeader
        setIsModalOpen={(val) => setIsModalOpen(val)}
        conditioner={conditioner}
        values={(val) => values(val)}
        changeValue={changeValue}
      />
      <TempSlider
        values={(val) => values(val)}
        conditioner={conditioner}
        changeValue={changeValue}
      />
      {modes && (
      <ModeContainer
        data={modes}
        title={t('mode')}
        iconSelector={(type, color) => ModeIconsSelectorModes(type, color)}
        changeValue={changeValue}
      />
      )}
      {fan && (
      <ModeContainer
        data={fan}
        title={t('speed')}
        iconSelector={(type, color) => ModeIconsSelectorSpeed(type, color)}
        changeValue={changeValue}
      />
      )}
      {lamellas && (
      <ModeContainer
        data={lamellas}
        title={t('airDirection')}
        iconSelector={(type, color) => ModeIconsSelectorSwim(type, color)}
        changeValue={changeValue}
      />
      )}
    </div>
  );
}

export default TemperatureModalContainer;
