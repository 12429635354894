import React from 'react';
import { useTranslation } from 'react-i18next';
import temIcon from '@assets/sensorIcons/temIcon.png';
import WaterIcon from '@assets/sensorIcons/WaterIcon';
import SensorContainer from './components/SensorContainer';
import SensorReverseContainer from './components/SensorReverseContainer';

const styles = {
  containerStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: '#FFF',
    boxShadow: '0px 0px 15px 0px rgba(146, 158, 175, 0.15)',
    borderRadius: 10,
    minWidth: '105px',
    marginTop: 10,
    marginBottom: 10,
  },

  titleStyle: {
    fontFamily: 'NunitoSans',
    fontWeight: '600',
    color: '#46ABD5',
    fontSize: 14,
    paddingTop: 10,
    paddingBottom: 4,
  },

  descStyle: {
    fontFamily: 'NunitoSans',
    fontWeight: '400',
    color: '#90CDE6',
    fontSize: 12,
    lineHeight: '21px',
  },
};

function SensorsSelector(item) {
  const { type, fb } = item;
  const typeOfSensor = type?.type_name;
  const { t } = useTranslation();
  switch (typeOfSensor) {
    case 'temperature':
      return (
        <div style={styles.containerStyle}>
          <img alt="temIcon" src={temIcon} style={{ height: 36, width: 36 }} />
          <span style={styles.titleStyle}>
            {t('temprature')}
          </span>
          <span style={styles.descStyle}>
            {`${Math.trunc(fb)} °C`}
          </span>
        </div>
      );
    case 'humidity':
      return (
        <div style={styles.containerStyle}>
          <WaterIcon offset={`${Number(100 - (typeof (fb) === 'number' ? fb : 100))}`} />
          <span style={styles.titleStyle}>
            {t('humidity')}
          </span>
          <span style={styles.descStyle}>
            {`${Math.trunc(fb)} %`}
          </span>
        </div>
      );
    case 'noise_level':
      return (
        <SensorContainer
          title={t('noise')}
          typeOfSensor={typeOfSensor}
          fb={fb}
          prefix="dBA"
        />
      );
    case 'co2_concentration':
      return (
        <SensorContainer
          title="CO2"
          typeOfSensor={typeOfSensor}
          fb={fb}
          prefix="ppm"
        />
      );
    case 'voc':
      return (
        <SensorContainer
          title="VOC"
          typeOfSensor={typeOfSensor}
          fb={fb}
          prefix="ppb"
        />
      );
    case 'TVOC':
      return (
        <SensorContainer
          title="TVOC"
          typeOfSensor={typeOfSensor}
          fb={fb}
          prefix="ppd"
        />
      );
    case 'CH2O':
      return (
        <SensorContainer
          title="CH2O"
          typeOfSensor={typeOfSensor}
          fb={fb}
          prefix="mg/m³"
        />
      );
    case 'light_level':
      return (
        <SensorReverseContainer
          title={t('illumination')}
          typeOfSensor={typeOfSensor}
          fb={fb}
          prefix="lux"
        />
      );
    default:
      return (null);
  }
} export default SensorsSelector;
