/* eslint-disable max-len */
import React from 'react';

function EcoIcon({ fill }) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.4403 4.38266C24.4278 4.1686 24.3372 3.96658 24.1855 3.81495C24.0339 3.66333 23.8319 3.57266 23.6178 3.56016C15.2911 3.07125 8.62142 5.57813 5.77767 10.2813C4.79195 11.8901 4.30621 13.7554 4.38205 15.6406C4.44439 17.3819 4.95189 19.1406 5.89033 20.8742L3.75642 23.007C3.59224 23.1712 3.5 23.3939 3.5 23.6261C3.5 23.8583 3.59224 24.081 3.75642 24.2452C3.92061 24.4093 4.14329 24.5016 4.37549 24.5016C4.60768 24.5016 4.83036 24.4093 4.99455 24.2452L7.12736 22.1113C8.85986 23.0486 10.6197 23.5561 12.3599 23.6184C12.4816 23.6228 12.603 23.625 12.7241 23.625C14.4869 23.6297 16.2164 23.1442 17.7192 22.2228C22.4224 19.3791 24.9303 12.7105 24.4403 4.38266ZM16.8169 20.7266C14.3286 22.2338 11.3831 22.2578 8.42674 20.8108L18.1206 11.118C18.2019 11.0367 18.2664 10.9402 18.3104 10.8339C18.3544 10.7277 18.3771 10.6139 18.3771 10.4989C18.3771 10.3839 18.3544 10.2701 18.3104 10.1639C18.2664 10.0577 18.2019 9.96115 18.1206 9.87985C18.0393 9.79855 17.9428 9.73406 17.8366 9.69007C17.7304 9.64607 17.6165 9.62342 17.5016 9.62342C17.3866 9.62342 17.2728 9.64607 17.1665 9.69007C17.0603 9.73406 16.9638 9.79855 16.8825 9.87985L7.18971 19.5781C5.74705 16.625 5.76783 13.6719 7.27392 11.188C9.69002 7.19907 15.4333 5.01485 22.7297 5.27516C22.9911 12.5661 20.8058 18.3105 16.8169 20.7266Z" fill={fill} />
    </svg>

  );
}

export default EcoIcon;
