import { Box } from '@mui/material';
import StyledSlider from '@UI/StyledSlider';
import lessLight from '@assets/lightsIcons/lessLight.png';
import moreLight from '@assets/lightsIcons/moreLight.png';
import StyledSwitch from '@UI/StyledSwitch';

const styles = {
  titleText: {
    fontFamily: 'NunitoSans',
    fontWeight: '700',
    color: '#46ABD5',
    fontSize: 14,
  },

  iconStyle: {
    height: 18,
    width: 18,
  },

  lightContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 35,
  },

  dimContainer: {
    alignItems: 'center',
    display: 'flex',
    paddingTop: 16,
  },

};
function ControlSelector(item, func, fbC, setFbC, name) {
  const {
    max_value, min_value, fb, id, address,
  } = item;

  switch (max_value) {
    case 1:
      return (
        <div style={styles.lightContainer}>
          <span style={styles.titleText}>
            {name}
          </span>
          <StyledSwitch
            checked={Boolean(fb)}
            onChange={() => { func(address, fb ? 0 : 1); }}
            inputProps={{ 'aria-label': 'ant design' }}
          />
        </div>
      );
    default:
      return (
        <Box style={styles.dimContainer}>
          <img alt="lessLight" src={lessLight} style={{ ...styles.iconStyle, paddingRight: 10 }} />
          <StyledSlider
            key={id}
            value={fbC}
            onChange={(evt, val) => setFbC(val)}
            onChangeCommitted={(evt, val) => func(address, val)}
            step={1}
            min={min_value}
            max={max_value}
          />
          <img alt="moreLight" src={moreLight} style={{ ...styles.iconStyle, paddingLeft: 10 }} />
        </Box>
      );
  }
} export default ControlSelector;
