/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom"
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import './localization/i18n';
import './assets/fonts/NunitoSans.ttf';

// const manifestJSON = {
//   short_name: 'SO Control TEST',
//   name: 'SO TEST',
//   icons: [
//     {
//       src: 'favicon.ico',
//       sizes: '64x64 32x32 24x24 16x16',
//       type: 'image/x-icon',
//     },
//     {
//       src: 'logo192.png',
//       type: 'image/png',
//       sizes: '192x192',
//     },
//     {
//       src: 'logo512.png',
//       type: 'image/png',
//       sizes: '512x512',
//     },
//   ],
//   start_url: '.',
//   display: 'standalone',
//   theme_color: '#000000',
//   background_color: '#ffffff',
// };
// const urlParams = new URLSearchParams(window.location.search);
// const roomId = urlParams.get('room_id');

// if (roomId) {
//   console.log('roomId', roomId);
//   manifestJSON.start_url = `https://simple-office-control.liis.su/?room_id=${roomId}`;
// }

// window.alert(JSON.stringify(manifestJSON));

// const stringManifest = JSON.stringify(manifestJSON);
// const blob = new Blob([stringManifest], { type: 'application/json' });
// const manifestURL = URL.createObjectURL(blob);
// document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);
// 
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
