import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import ColorButton from '@UI/ColorButton';

import '@splidejs/react-splide/css';

const styles = {
  titleStyle: {
    fontFamily: 'NunitoSans',
    fontWeight: '700',
    color: '#666666',
    fontSize: 14,
  },

  textButtonStyle: {
    fontFamily: 'NunitoSans',
    fontWeight: '600',
    fontSize: 14,
    marginLeft: 8,
  },

  buttonStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    borderRadius: 10,
    boxShadow: ' 0px 0px 15px 0px  rgba(146, 158, 175, 0.15)',
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 4,
    marginRight: 4,
    height: 52,
    minWidth: 52,
  },
};

function ModeContainer(props) {
  const {
    title, data, iconSelector, changeValue,
  } = props;
  const currItem = (val) => data.fb === val;
  const isLamelas = data.type?.type_name === 'lamellas';
  const selectedColor = isLamelas ? '#404040' : '#929EAF';
  const icon = (val) => iconSelector(val.type, currItem(val.value) ? '#FFFFFF' : selectedColor);
  const onPressItem = (val) => {
    changeValue(data.address, val);
  };
  return (
    <div>
      <span style={styles.titleStyle}>
        {title}
      </span>
      <Splide
        aria-label="modes"
        style={{ marginTop: 8, marginBottom: 28 }}
        options={{
          gap: '8px',
          autoWidth: true,
          arrows: false,
          pagination: false,
          drag: 'free',
        }}
      >
        {data.modes.map((item) => (
          <SplideSlide key={item?.value}>
            <ColorButton
              style={{ ...styles.buttonStyle, backgroundColor: currItem(item.value) ? '#6BBCDE' : '#FFFFFF' }}
              onClick={() => onPressItem(item.value)}
            >
              {icon(item)}
              {!isLamelas && (
              <span style={{
                ...styles.textButtonStyle,
                color: currItem(item.value) ? '#FFFFFF' : '#929EAF',
                marginLeft: icon(item) ? 8 : 0,
              }}
              >
                {item?.name}
              </span>
              )}
            </ColorButton>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}

export default ModeContainer;
