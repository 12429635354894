import React from 'react';

import offWhiteIcon from '@assets/multimediaIcons/offWhiteIcon.png';
import ColorButton from '@UI/ColorButton';
import ModalHeader from './components/ModalHeader';
import Joystick from './components/Joystick';
import Volume from './components/Volume';
import OptionsButtons from './components/OptionsButtons';
import Selector from './components/Selector';

const styles = {
  mainContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  offButtonContainer: {
    display: 'flex',
    backgroundColor: 'rgba(70, 171, 213, 1)',
    borderRadius: 40,
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tvSelectOffContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 50,
  },
  bottomContainer: {
    display: 'flex',
    flex: 1,
    paddingBottom: 30,
  },
};

function MultimediaModalContainer(props) {
  const {
    setIsModalOpen, multimedia, func,
  } = props;
  const onPressToAction = (pickedType) => {
    // console.log('pickedType', pickedType);
    const pickedItem = multimedia[0].capabilities.find((item) => item.type.type_name === pickedType);
    if (pickedItem) {
      // console.log('pickedItem', pickedItem);
      func(pickedItem.address, pickedItem.value);
    }
  };
  return (
    <div style={styles.mainContainer}>
      <ModalHeader setIsModalOpen={setIsModalOpen} />
      <div style={styles.tvSelectOffContainer}>
        {/* <Selector /> */}
        <ColorButton
          onClick={() => onPressToAction('TV_on_off')}
          style={styles.offButtonContainer}
        >
          <img alt="offWhiteIcon" src={offWhiteIcon} style={{ height: 16, width: 16 }} />
        </ColorButton>
      </div>
      <Joystick onPressToAction={(type) => onPressToAction(type)} />
      <div style={styles.bottomContainer}>
        <Volume onPressToAction={(type) => onPressToAction(type)} />
        <OptionsButtons onPressToAction={(type) => onPressToAction(type)} />
      </div>
    </div>
  );
}

export default MultimediaModalContainer;
