import goodStatus from '@assets/sensorIcons/goodStatus.png';
import standartStatus from '@assets/sensorIcons/standartStatus.png';
import badStatus from '@assets/sensorIcons/badStatus.png';
import sensorsRanges from './sensorsRanges';

function QualitySelector(value, typeOfSensor) {
  switch (true) {
    case (value <= sensorsRanges(typeOfSensor).good):
      return ({
        titleColor: '#46ABD5',
        descriptionColor: '#90CDE6',
        img: <img alt="goodStatus" src={goodStatus} style={{ height: 36, width: 36 }} />,
      });
    case (value > sensorsRanges(typeOfSensor).good && value < sensorsRanges(typeOfSensor).bad):
      return (
        {
          titleColor: '#FE8F29',
          descriptionColor: '#FEBC7F',
          img: <img alt="standartStatus" src={standartStatus} style={{ height: 36, width: 36 }} />,
        }
      );
    case (value >= sensorsRanges(typeOfSensor).bad):
      return (
        {
          titleColor: '#DA4040',
          descriptionColor: '#E98C8C',
          img: <img alt="badStatus" src={badStatus} style={{ height: 36, width: 36 }} />,
        }
      );
    default:
      return (null);
  }
} export default QualitySelector;

export function QualitySelectorReverse(value, typeOfSensor) {
  switch (true) {
    case (value >= sensorsRanges(typeOfSensor).good):
      return ({
        titleColor: '#46ABD5',
        descriptionColor: '#90CDE6',
        img: <img alt="goodStatus" src={goodStatus} style={{ height: 36, width: 36 }} />,
      });
    case (value < sensorsRanges(typeOfSensor).good && value >= sensorsRanges(typeOfSensor).bad):
      return (
        {
          titleColor: '#FE8F29',
          descriptionColor: '#FEBC7F',
          img: <img alt="standartStatus" src={standartStatus} style={{ height: 36, width: 36 }} />,
        }
      );
    case (value < sensorsRanges(typeOfSensor).bad):
      return (
        {
          titleColor: '#DA4040',
          descriptionColor: '#E98C8C',
          img: <img alt="badStatus" src={badStatus} style={{ height: 36, width: 36 }} />,
        }
      );
    default:
      return (null);
  }
}
