import React from 'react';

import { SnackbarProvider, MaterialDesignContent } from 'notistack';
import { styled } from '@mui/material/styles';
import reconnectIcon from '@assets/connectionIcons/reconnectIcon.png';
import okConnectionIcon from '@assets/connectionIcons/okConnectionIcon.png';

function NotifyProvider({ children }) {
  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
      backgroundColor: 'rgba(129, 213, 133, 1)',
      display: 'flex',
      alingnItems: 'center',
      justifyContent: 'center',
      borderRadius: 6,
      boxShadow: '0px 0px',
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: 'rgba(254, 165, 84, 1)',
      display: 'flex',
      alingnItems: 'center',
      justifyContent: 'center',
      borderRadius: 6,
      boxShadow: '0px 0px',
    },
  }));

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={2000}
      iconVariant={{
        success: <img alt="okConnectionIcon" src={okConnectionIcon} style={{ height: 18, width: 18, marginRight: 12 }} />,
        error: <img alt="reconnectIcon" src={reconnectIcon} style={{ height: 18, width: 18, marginRight: 12 }} />,
      }}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
      }}
    >
      {children}
    </SnackbarProvider>
  );
}
export default NotifyProvider;
