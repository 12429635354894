import React from 'react';
import { useTranslation } from 'react-i18next';
import logoSCBig from '@assets/logos/logoSCBig.png';
import noConnectionLogo from '@assets/connectionIcons/noConnectionLogo.png';
import ColorButton from '@components/UI/ColorButton';

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#46ABD5',
    paddingTop: 50,
    paddingBottom: 70,
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  text: {
    fontFamily: 'NunitoSans',
    fontWeight: '700',
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
  },
  buttonText: {
    fontFamily: 'NunitoSans',
    fontWeight: '700',
    color: 'white',
    fontSize: 18,
    textAlign: 'center',
    textDecorationLine: 'underline',
  },
};

function NoConnectionScreen({ networkName }) {
  const { t } = useTranslation();
  return (
    <div
      className="container"
      style={styles.container}
    >
      <img
        alt="logoSCBig"
        src={logoSCBig}
        style={{ maxWidth: '70%', height: 'auto' }}
      />
      <img
        alt="noConnectionLogo"
        src={noConnectionLogo}
        style={{ maxWidth: '60%', height: 'auto' }}
      />
      <span style={styles.text}>
        {`${t('pleaseCheckWifi')} ${networkName ? `"${networkName}"` : ''} ${t('andOffVPN')}`}
      </span>
      <ColorButton
        onClick={() => window.location.reload(false)}
        style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
        }}
      >
        <span style={styles.buttonText}>
          {t('tryAgain')}
        </span>
      </ColorButton>
    </div>
  );
}

export default NoConnectionScreen;
