import React from 'react';
import { useTranslation } from 'react-i18next';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import SensorsSelector from './SensorsSelector';

const styles = {
  container: {
    paddingTop: 10,
    paddingBottom: 30,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'NunitoSans',
    fontWeight: '700',
    color: '#666666',
    fontSize: 16,
    paddingLeft: 20,
  },
  sensorsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    flex: 1,
  },
};

function SensorsContainer(props) {
  const {
    sensors,
  } = props;
  const { t } = useTranslation();
  return (
    sensors?.length > 0 && (
      <div style={styles.container}>
        <span style={styles.title}>
          {t('sensors')}
        </span>
        <Splide
          aria-label="modes"
          style={{ paddingTop: 5, paddingBottom: 20 }}
          options={{
            gap: '10px',
            autoWidth: true,
            arrows: false,
            pagination: false,
            drag: 'free',
            padding: 20,
          }}
        >
          {(sensors[0].capabilities?.map((item) => (
            <SplideSlide key={item?.id}>
              {SensorsSelector(item)}
            </SplideSlide>
          )))}
        </Splide>
      </div>
    )
  );
}

export default SensorsContainer;
