import React from 'react';
import Temperature from './Temperature';

const container = {
  display: 'flex',
  flex: 1,
  marginTop: 31,
  justifyContent: 'space-between',
};

function TemperatureContainer(props) {
  const {
    conditioner, changeValue, setModalObject,
  } = props;
  return (
    conditioner?.length > 0 && (
    <div style={container}>
      <Temperature
        changeValue={(address, value) => changeValue(address, value)}
        conditioner={conditioner}
        setIsModalOpen={(val) => setModalObject(val, 'temperature')}
      />
    </div>
    )
  );
}

export default TemperatureContainer;
