import React from 'react';
import { useTranslation } from 'react-i18next';
import Scenario from './Scenario';

const titleText = {
  fontFamily: 'NunitoSans',
  fontWeight: '700',
  color: '#404040',
  fontSize: 16,
  paddingBottom: 15,
  paddingTop: 30,
};

function ScenarioContainer(props) {
  const { t } = useTranslation();
  const {
    scenarios, changeValue,
  } = props;

  return (
    scenarios?.length > 0 && (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={titleText}>
        {t('scenarios')}
      </span>
      {(scenarios?.map((item) => (
        <Scenario
          key={item.id}
          item={item}
          func={(address, value, qos) => changeValue(address, value, qos)}
        />
      )))}
    </div>
    )
  );
}

export default ScenarioContainer;
