import React, { Suspense, useEffect } from 'react';
import './styles/App.css';
import GetRoomScreen from '@screens/GetRoomScreen';
import { amlitudeInitialization, logEvent } from '@libraries/AmplitudeAnalythics/init';
import AMPLITUDE_EVENT_NAMES from '@libraries/AmplitudeAnalythics/events';
import NotifyProvider from '@Providers/NotifyProvider';

function App() {
  useEffect(() => {
    amlitudeInitialization();
    logEvent(AMPLITUDE_EVENT_NAMES.OPEN_APP);
  }, []);
  return (
    <Suspense fallback="loading">
      <NotifyProvider>
        <GetRoomScreen />
      </NotifyProvider>
    </Suspense>
  );
}
export default App;
